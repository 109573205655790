import React, { useContext } from 'react';
import { GlobalDataContext } from '../../context/context';
import { GiPositionMarker } from "react-icons/gi";


const BlockMaps = () => {
  const { rpdata } = useContext(GlobalDataContext);

  return (
    <>
        <div className='flex flex-wrap my-16 py-10 md:py-10 bg-[#333333]'>
            <div className='w-full justify-center items-center flex'>
                <GiPositionMarker className='text-6xl text-white'/>
                <h2 className='text-center text-white'>{rpdata?.dbSlogan?.[7]?.slogan}</h2>
            </div>
            <div className='flex md:border-r-2 text-center w-full md:w-[25%] p-14 bg-[#333333]'>
                {/* <BiMapPin className='text-white w-10 h-10'/> */}
                <ul className='list-disc pl-5'>
                    {
                        rpdata?.dbPrincipal?.location?.slice(0, 7).map((item, index) => {
                            return (
                                <li key={index} className='grid grid-cols-1 leading-8'>
                                    
                                    <span className=' text-white text-left flex'><GiPositionMarker /> {item.address}</span>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
            <div className='flex md:border-r-2 text-center w-full md:w-[25%] p-14 bg-[#333333]'>
            {/* <BiMapPin className='text-white w-10 h-10'/> */}
            <ul className='list-disc pl-5'>
                    {
                        rpdata?.dbPrincipal?.location?.slice(7, 14).map((item, index) => {
                            return (
                                <li key={index} className='grid grid-cols-1 leading-8'>
                                    
                                    <span className=' text-white text-left flex'><GiPositionMarker /> {item.address}</span>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
            <div className='flex md:border-r-2 text-center w-full md:w-[25%] p-14 bg-[#333333]'>
            {/* <BiMapPin className='text-white w-10 h-10'/> */}
            <ul className='list-disc pl-5'>
                    {
                        rpdata?.dbPrincipal?.location?.slice(14, 21).map((item, index) => {
                            return (
                                <li key={index} className='grid grid-cols-1 leading-8'>
                                    
                                    <span className=' text-white text-left flex'><GiPositionMarker /> {item.address}</span>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
            <div className='flex md:border-r-2 text-center w-full md:w-[25%] p-14 bg-[#333333]'>
            {/* <BiMapPin className='text-white w-10 h-10'/> */}
            <ul className='list-disc pl-5'>
                    {
                        rpdata?.dbPrincipal?.location?.slice(21, 27).map((item, index) => {
                            return (
                                <li key={index} className='grid grid-cols-1 leading-8'>
                                    
                                    <span className=' text-white text-left flex'><GiPositionMarker /> {item.address}</span>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </div>
    </>
  )
}

export default BlockMaps;